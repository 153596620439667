import Api from '@/services/Index';

// PARAMETER ITEMS
const getAllItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/items', config);
}

const getItems = async (code, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/' + code + '/items', config);
}

const getItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/items/' + id);
}

const storeItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/parameter/items', config);
}
const updateItems = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/parameter/items/' + id, formData);
}

const deleteItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/parameter/items/' + id);
}

export default {
    getAllItems,
    getItems,
    getItem,
    storeItems,
    updateItems,
    deleteItem
}
